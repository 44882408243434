import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

// all api calling using rtk query
import userApis from "./user/userApis";
import locationApis from "./location/locationApis";
import categoryApis from "./category/categoryApis";
import ingredientsApis from "./ingredients/ingredientsApis";
import nutritionsApis from "./nutritions/nutritionsApis";
import productsApis from "./products/productsApis";
import ordersApis from "./orders/ordersApis";

// redux state slices
import commonSlice from "./common/commonSlice";
import userSlice from "./user/userSlice";
import categorySlice from "./category/categorySlice";
import locationSlice from "./location/locationSlice";
import ingredientsSlice from "./ingredients/ingredientsSlice";
import nutritionsSlice from "./nutritions/nutritionsSlice";
import productsSlice from "./products/productsSlice";
import ordersSlice from "./orders/ordersSlice";

// axios interceptor
import axiosInterceptor from "../helpers/axios";

const reducers = {
    [commonSlice.name]: commonSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [locationSlice.name]: locationSlice.reducer,
    [categorySlice.name]: categorySlice.reducer,
    [ingredientsSlice.name]: ingredientsSlice.reducer,
    [nutritionsSlice.name]: nutritionsSlice.reducer,
    [productsSlice.name]: productsSlice.reducer,
    [ordersSlice.name]: ordersSlice.reducer,

    [userApis.reducerPath]: userApis.reducer,
    [locationApis.reducerPath]: locationApis.reducer,
    [categoryApis.reducerPath]: categoryApis.reducer,
    [ingredientsApis.reducerPath]: ingredientsApis.reducer,
    [nutritionsApis.reducerPath]: nutritionsApis.reducer,
    [productsApis.reducerPath]: productsApis.reducer,
    [ordersApis.reducerPath]: ordersApis.reducer,
};

const initialState = {};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat([
            userApis.middleware,
            locationApis.middleware,
            categoryApis.middleware,
            ingredientsApis.middleware,
            nutritionsApis.middleware,
            productsApis.middleware,
            ordersApis.middleware,
        ]);
    },
    devTools: true,
    preloadedState: initialState,
    enhancers: (defaultEnhancers) => [...defaultEnhancers]
});


export const useAppSelector = () => useSelector(rootReducer);
export const useAppDispatch = () => useDispatch(store.dispatch);

axiosInterceptor(store.dispatch);

export default store;