import React from "react";
import { Chip, Divider, Drawer, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import OrdersItemTableComponent from "./OrdersItemTable";
import Input from "../../Common/Input";
import StyledTextarea from "../../Common/StyledTextarea";
import ReportIcon from '@mui/icons-material/Report';
import { useUpdateOrdersMutation } from "../../../store/orders/ordersApis";
import { OrderStatus } from "../../../helpers/constants";

export const OrdersDetailsDrawer = ({ initialValues, show, close }) => {
    const dispatch = useDispatch();
    const [updateOrders, { isLoading: updateOrdersLoading }] = useUpdateOrdersMutation();

    const changeOrderStatus = async (status) => {
        try {
            let payload = {
                order_number: initialValues.order_number,
                status: status.toString()
            }
            await updateOrders(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Order status updated successfully",
                severity: "success",
            }))
            close();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    };

    return (
        <Drawer
            anchor={"right"}
            open={show}
            PaperProps={{
                sx: { width: { xs: '100%', md: '70%', sm: "70%", lg: "70%" } },
            }}
            onClose={() => close()}
        >
            <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                <Grid container flex={0} px={1} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                    <Grid item alignSelf={"center"}>
                        <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                    </Grid>
                    <Grid item alignSelf={"center"}>
                        <Typography variant="h6">Order Id: #{initialValues.order_number}</Typography>
                    </Grid>
                    {
                        parseInt(initialValues.status) !== OrderStatus.DELIVERED && parseInt(initialValues.status) !== OrderStatus.CANCELLED
                            ?
                            <Grid item sx={{ position: "absolute", right: 10 }}>
                                <Button size="large" color="error" type="button" onClick={() => changeOrderStatus(OrderStatus.CANCELLED)} >
                                    Cancel Order
                                </Button>
                            </Grid>
                            :
                            null
                    }
                </Grid>
                <Grid flex={1} px={2} py={5} overflow={"auto"}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={700}>Product Details</Typography>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <OrdersItemTableComponent data={initialValues.order_items} loading={false} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} pr={5} pb={2}>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">Item Total:</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.subtotal?.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">Discount (-):</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.offer_discount?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">Loyalty Points (-):</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.applied_coins?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">Convenience Fee:</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.convenience_fee?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={1} pr={5} pt={2}>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">Total:</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{(parseInt(initialValues?.subtotal || 0) + parseInt(initialValues?.convenience_fee || 0)) - (parseInt(initialValues?.offer_discount || 0) + parseInt(initialValues?.applied_coins || 0))}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">CGST (2.5%):</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.cgst?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">SGST (2.5%):</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.sgst?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6">TIP:</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" fontWeight={"700"}>{initialValues.tip?.toFixed(2) || 0.00}</Typography>
                        </Grid>
                        <Grid item xs={10} textAlign={"right"}>
                            <Typography variant="h6" color={"error"}>Grand Total:</Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                            <Typography variant="h6" color={"error"} fontWeight={"700"}>{initialValues.grand_total?.toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pr={5} pt={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" fontWeight={700}>Delivery Info</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Name"
                                value={initialValues?.customerAddressData?.name}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Mobile"
                                value={initialValues?.customerAddressData?.mobile}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="City"
                                value={initialValues?.customerAddressData?.city}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Pincode"
                                value={initialValues?.customerAddressData?.pincode}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Address Line 1"
                                value={initialValues?.customerAddressData?.address_line_1}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Address Line 2"
                                value={initialValues?.customerAddressData?.address_line_2}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pr={5} pt={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" fontWeight={700}>Customer Info</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="name"
                                label="Customer Name"
                                value={initialValues?.customerData?.name}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                id='mobile'
                                name="mobile"
                                label="Customer Mobile"
                                value={initialValues?.customerData?.mobile}
                                fullWidth
                                disabled
                                size="large"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pr={5} pt={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" fontWeight={700}>Special instructions</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2">{initialValues?.instruction}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            {
                                parseInt(initialValues.status) === OrderStatus.CANCELLED
                                    ?
                                    <Chip size="large" color="error" variant="contained" label="Cancelled" ></Chip>
                                    :
                                    parseInt(initialValues.status) === OrderStatus.CREATED
                                        ?
                                        <Button loading={updateOrdersLoading} size="large" color="warning" type="button" onClick={() => changeOrderStatus(OrderStatus.ACCEPTED)}>Accept Order</Button>
                                        :
                                        parseInt(initialValues.status) === OrderStatus.ACCEPTED
                                            ?
                                            <Button loading={updateOrdersLoading} size="large" color="warning" type="button" onClick={() => changeOrderStatus(OrderStatus.READYFORDELIVERY)}>Ready for delivery</Button>
                                            :
                                            parseInt(initialValues.status) === OrderStatus.READYFORDELIVERY
                                                ?
                                                <Button loading={updateOrdersLoading} size="large" color="warning" type="button" onClick={() => changeOrderStatus(OrderStatus.OUTFORDELIVERY)}>Out for delivery</Button>
                                                :
                                                parseInt(initialValues.status) === OrderStatus.OUTFORDELIVERY
                                                    ?
                                                    <Button loading={updateOrdersLoading} size="large" color="success" type="button" onClick={() => changeOrderStatus(OrderStatus.DELIVERED)}>Order Delivered</Button>
                                                    :
                                                    <Chip size="large" color="success" variant="contained" label="Completed" ></Chip>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}