// export const baseUrl = "http://localhost:7057"
export const baseUrl = "https://api.grammyfoods.com"

export const REACT_APP_LOGIN_URL = "/auth/login";

export const drawerWidth = 280;

export const Roles = {
    CUSTOMER: 0,
    ADMIN: 1
}
export const RolesByNumber = {
    0: "Customer",
    1: "Admin"
}

export const PermissionsByNumber = {
    0: "None",
    1: "All",
    2: "Orders"
}

export const Permissions = {
    NONE: 0,
    ALL: 1,
    ORDERS: 2,
}

export const OrderStatus = {
    CREATED: 0,
    ACCEPTED: 1,
    READYFORDELIVERY: 2,
    OUTFORDELIVERY: 3,
    DELIVERED: 4,
    CANCELLED: 5
}

export const OrderStatusByNumber = {
    0: "Placed",
    1: "Accepted",
    2: "Ready For Delivery",
    3: "Oot For Delivery",
    4: "Delivered",
    5: "Cancelled"
}