import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeInitialState } from "../../../store/products/productsSlice";

const mapStateToProps = (state) => {
    return {
        formType: state.products.formType,
        initialValues: state.products.initialValues,
        showDrawer: state.products.showDrawer,
    };
};

const mapDispatch = {
    changeInitialState
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;