import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { CategoryParser, categoryListParser } from './categoryParser';

const categoryApis = createApi({
    reducerPath: 'categoryApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Category"],
    endpoints: build => ({
        getCategoryList: build.query({
            query: (params) => ({
                url: "/category",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => categoryListParser(response),
            providesTags: ['Category'],
            keepUnusedDataFor: 5,
        }),
        addNewCategory: build.mutation({
            query: (payload) => ({
                url: "/category",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => CategoryParser(response),
            invalidatesTags: ['Category'],
        }),
        updateCategory: build.mutation({
            query: (payload) => ({
                url: "/category",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => CategoryParser(response),
            invalidatesTags: ['Category'],
        }),
        deleteCategory: build.mutation({
            query: (payload) => ({
                url: "/category",
                method: "DELETE",
                body: payload
            }),
            transformResponse: (response) => CategoryParser(response),
            invalidatesTags: ['Category'],
        }),
    }),
});

export const { useGetCategoryListQuery, useAddNewCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation } = categoryApis;
export default categoryApis;