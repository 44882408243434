import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { format } from "date-fns";
import { OrderStatus, OrderStatusByNumber } from "../../../helpers/constants";

const OrdersTableComponent = ({ loading, data, edit }) => {
    let columns = [
        {
            title: "Sr. No.",
        },
        {
            title: "Order Number",
        },
        {
            title: "Pincode",
        },
        {
            title: "Order & Delivery Date",
        },
        {
            title: "Amount",
        },
        {
            title: "Status",
        },
        {
            title: "Action",
        }
    ];

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const { order_number } = row;
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{order_number}</TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{row?.pincode}</Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="subtitle2">Order Date: {format(new Date(row.createdAt), "dd-MM-yyyy hh:mm:ss a")}</Typography>
                        <Typography variant="subtitle2">Delivery Date: {format(new Date(row.delivery_date), "dd-MM-yyyy hh:mm:ss a")}</Typography>
                    </TableCell>
                    <TableCell align="center">{row.grand_total}</TableCell>
                    <TableCell align="center">
                        {
                            parseInt(row.status) === OrderStatus.DELIVERED
                                ?
                                <Chip label={OrderStatusByNumber[row.status]} color="success" variant="contained" />
                                :
                                parseInt(row.status) === OrderStatus.CANCELLED
                                    ?
                                    <Chip label={OrderStatusByNumber[row.status]} color="error" variant="contained" />
                                    :
                                    <Chip label={OrderStatusByNumber[row.status]} color="primary" variant="contained" />
                        }

                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row.order_number)}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={6} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default OrdersTableComponent;