import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { PermissionsByNumber, Roles, RolesByNumber } from "../../../helpers/constants";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDeleteUserMutation } from "../../../store/user/userApis";

const UserTableComponent = ({ loading, data, edit }) => {
    const [handleDeleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();
    const dispatch = useDispatch();
    let columns = [
        {
            title: "Id",
        },
        {
            title: "Role",
        },
        {
            title: "Name",
        },
        {
            title: "Email",
        },
        {
            title: "Mobile",
        },
        {
            title: "Action",
        },
    ];

    const handleDelete = async (id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                await handleDeleteUser({ id }).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "User deleted successfully",
                    severity: "success",
                }))
            }
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const { id } = row;
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{id}</TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{RolesByNumber[row.role]}</Typography>
                        {
                            row.permissions !== Roles.CUSTOMER
                                ?
                                <Typography variant="subtitle2">Permission: {PermissionsByNumber[row.permissions]}</Typography>
                                :
                                null
                        }

                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row?.email}</TableCell>
                    <TableCell align="center">{row?.mobile}</TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="info"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        <IconButtonIcons
                            title="Delete"
                            IconComponent={DeleteOutline}
                            color="info"
                            onClick={() => handleDelete(row.id)}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={6} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default UserTableComponent;