import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: {
        name: "",
        status: ""
    }
};

export const nutritionsSlice = createSlice({
    name: "nutritions",
    initialState,
    reducers: {
        changeInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeInitialState } = nutritionsSlice.actions;

export default nutritionsSlice;
