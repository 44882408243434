import React from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { CategoryAddDrawer } from "../../../components/admin/category-manager/CategoryAddDrawer";
import { useDeleteCategoryMutation, useGetCategoryListQuery } from "../../../store/category/categoryApis";
import CategoryTableComponent from "../../../components/admin/category-manager/CategoryTable";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";

const CategoryManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { showDrawer, formType, initialValues } = props;
    const { isLoading, data: category } = useGetCategoryListQuery();
    const { handleDeleteCategory } = useDeleteCategoryMutation();

    const handleAddCategory = () => {
        props.changeInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    const handleDrawerClose = () => {
        props.changeInitialState({ showDrawer: false, formType: "", initialValues: {} })
    }

    const handleCategoryEdit = (data, type) => {
        props.changeInitialState({ showDrawer: true, formType: type, initialValues: data })
    }

    const handleCategoryDelete = async (data, type) => {
        try {
            await handleDeleteCategory({ id: data.id }).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Category deleted successfully",
                severity: "error",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Category</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddCategory()}
                        >
                            Add new Category
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <CategoryTableComponent edit={(val, type) => handleCategoryEdit(val, type)} deleteAction={(val) => handleCategoryDelete(val)} loading={isLoading} data={category} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <CategoryAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default CategoryManagerContainer;