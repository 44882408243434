import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { IngredientsParser, ingredientsListParser } from './ingredientsParser';

const ingredientsApis = createApi({
    reducerPath: 'ingredientsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Ingredients"],
    endpoints: build => ({
        getIngredientsList: build.query({
            query: (params) => ({
                url: "/ingredients",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => ingredientsListParser(response),
            providesTags: ['Ingredients'],
            keepUnusedDataFor: 5,
        }),
        addNewIngredients: build.mutation({
            query: (payload) => ({
                url: "/ingredients",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => IngredientsParser(response),
            invalidatesTags: ['Ingredients'],
        }),
        updateIngredients: build.mutation({
            query: (payload) => ({
                url: "/ingredients",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => IngredientsParser(response),
            invalidatesTags: ['Ingredients'],
        }),
        deleteIngredients: build.mutation({
            query: (payload) => ({
                url: "/ingredients",
                method: "DELETE",
                body: payload
            }),
            transformResponse: (response) => IngredientsParser(response),
            invalidatesTags: ['Ingredients'],
        }),
    }),
});

export const { useGetIngredientsListQuery, useAddNewIngredientsMutation, useUpdateIngredientsMutation, useDeleteIngredientsMutation } = ingredientsApis;
export default ingredientsApis;