import * as yup from "yup";

export const UserValidation = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().required("Required"),
    mobile: yup.string().required("Required"),
    status: yup.string().required("Required"),
    password: yup.string(),
    permissions: yup.string().required("Required"),
    role: yup.string().required("Required"),
    assign_pincode: yup.array(),
});