import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { NutritionsParser, nutritionsListParser } from './nutritionsParser';

const nutritionsApis = createApi({
    reducerPath: 'nutritionsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Nutritions"],
    endpoints: build => ({
        getNutritionsList: build.query({
            query: (params) => ({
                url: "/nutritions",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => nutritionsListParser(response),
            providesTags: ['Nutritions'],
            keepUnusedDataFor: 5,
        }),
        addNewNutritions: build.mutation({
            query: (payload) => ({
                url: "/nutritions",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => NutritionsParser(response),
            invalidatesTags: ['Nutritions'],
        }),
        updateNutritions: build.mutation({
            query: (payload) => ({
                url: "/nutritions",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => NutritionsParser(response),
            invalidatesTags: ['Nutritions'],
        }),
        deleteNutritions: build.mutation({
            query: (payload) => ({
                url: "/nutritions",
                method: "DELETE",
                body: payload
            }),
            transformResponse: (response) => NutritionsParser(response),
            invalidatesTags: ['Nutritions'],
        }),
    }),
});

export const { useGetNutritionsListQuery, useAddNewNutritionsMutation, useUpdateNutritionsMutation, useDeleteNutritionsMutation } = nutritionsApis;
export default nutritionsApis;