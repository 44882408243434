import React from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { ProductsAddDrawer } from "../../../components/admin/products-manager/ProductsAddDrawer";
import { useDeleteProductsMutation, useGetProductsListQuery, useGetSingleProductMutation } from "../../../store/products/productsApis";
import ProductsTableComponent from "../../../components/admin/products-manager/ProductsTable";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";

const ProductsManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { showDrawer, formType, initialValues } = props;
    const { isLoading, data: products } = useGetProductsListQuery();
    const [handleGetSingleProduct] = useGetSingleProductMutation();
    const [handleDeleteProducts, { isLoading: deleteProductsLoading }] = useDeleteProductsMutation();

    const handleAddProducts = () => {
        props.changeInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    const handleDrawerClose = () => {
        props.changeInitialState({ showDrawer: false, formType: "", initialValues: {} })
    }

    const handleProductsEdit = async (data, type) => {
        let result = await handleGetSingleProduct({ id: data.id });
        if (result.data) {
            props.changeInitialState({ showDrawer: true, formType: type, initialValues: result.data })
        }
    }

    const handleProductsDelete = async (data, type) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                await handleDeleteProducts({ id: data.id }).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Products deleted successfully",
                    severity: "success",
                }))
            }
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Products</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddProducts()}
                        >
                            Add new Products
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <ProductsTableComponent edit={(val, type) => handleProductsEdit(val, type)} deleteAction={(val) => handleProductsDelete(val)} loading={isLoading} data={products} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <ProductsAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default ProductsManagerContainer;