import React from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { LocationAddDrawer } from "../../../components/admin/location-manager/LocationAddDrawer";
import { useDeleteLocationMutation, useGetLocationListQuery } from "../../../store/location/locationApis";
import LocationTableComponent from "../../../components/admin/location-manager/LocationTable";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";

const LocationManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { showDrawer, formType, initialValues } = props;
    const { isLoading, data: location } = useGetLocationListQuery();
    const { handleDeleteLocation } = useDeleteLocationMutation();

    const handleAddLocation = () => {
        props.changeInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    const handleDrawerClose = () => {
        props.changeInitialState({ showDrawer: false, formType: "", initialValues: {} })
    }

    const handleLocationEdit = (data, type) => {
        props.changeInitialState({ showDrawer: true, formType: type, initialValues: data })
    }

    const handleLocationDelete = async (data, type) => {
        try {
            await handleDeleteLocation({ id: data.id }).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Location deleted successfully",
                severity: "error",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Location</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddLocation()}
                        >
                            Add new Location
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <LocationTableComponent edit={(val, type) => handleLocationEdit(val, type)} deleteAction={(val) => handleLocationDelete(val)} loading={isLoading} data={location} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <LocationAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default LocationManagerContainer;