import React, { useState } from "react";
import { Grid, InputBase, Paper, Stack, Typography } from "@mui/material";
import { useGetOrdersListQuery, useGetSingleOrderMutation } from "../../../store/orders/ordersApis";
import OrdersTableComponent from "../../../components/admin/orders-manager/OrdersTable";
import { OrdersDetailsDrawer } from "../../../components/admin/orders-manager/OrdersDetailsDrawer";

const OrdersManagerContainer = (props) => {
    const { showDrawer, initialValues } = props;
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [filter, setFilter] = useState(null);
    const { isLoading, data: orders } = useGetOrdersListQuery(filter);
    const [handleGetSingleOrder] = useGetSingleOrderMutation();

    React.useEffect(() => {
        const getData = setTimeout(() => {
            if (searchKeyword)
                setFilter({ keywords: searchKeyword });
            else
                setFilter(null);
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchKeyword])

    const handleDrawerClose = () => {
        props.changeInitialState({ showDrawer: false, initialValues: {} })
    }

    const handleOrdersEdit = async (order_number, type) => {
        let result = await handleGetSingleOrder({ order_number });
        if (result.data) {
            props.changeInitialState({ showDrawer: true, initialValues: result.data })
        }
    }
    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Orders</Typography>
                    </Grid>
                    <Grid item>
                        <Paper
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 3px 2px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px" }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Orders Here..."
                                inputProps={{ 'aria-label': 'search orders here' }}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <OrdersTableComponent edit={(val, type) => handleOrdersEdit(val, type)} loading={isLoading} data={orders} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <OrdersDetailsDrawer show={showDrawer} close={handleDrawerClose} initialValues={initialValues} />

        </Stack >
    );
}

export default OrdersManagerContainer;