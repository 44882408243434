import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import TableCommon from "../../Common/Table";

const OrdersItemTableComponent = ({ loading, data }) => {
    let columns = [
        {
            title: "Sr. No.",
        },
        {
            title: "Image",
        },
        {
            title: "Product Name",
        },
        {
            title: "Qty",
        },
        {
            title: "Price",
        }
    ];

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                        <img src={row.image} alt="Product" width={90} />
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">#{row.product_id} - {row.product_name}</Typography>
                    </TableCell>
                    <TableCell align="center">{row.qty}</TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2" fontWeight={600}>{parseInt(row.price) * parseInt(row.qty)}</Typography>
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={6} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default OrdersItemTableComponent;