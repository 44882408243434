import React from "react";

import BaseLayout from "../../components/layout/BaseLayout";
import AdminRoutes from "../../routes/AdminRoutes";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from '@mui/icons-material/Person2Outlined';
import CategoryIcon from '@mui/icons-material/Category';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DvrIcon from '@mui/icons-material/Dvr';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { withTheme } from "@emotion/react";

class LayoutContainer extends React.Component {
    render() {
        return (
            <BaseLayout
                params={this.props.params}
                modules={adminModules}
                ChildComponent={AdminRoutes}
            />
        );
    }
}
export default withTheme(LayoutContainer);

const adminModules = [
    {
        id: "dashboard",
        title: "Dashboard",
        link: "/dashboard",
        logo: <HomeIcon />,
        permissions: [1,2]
    },
    {
        id: "user-manager",
        title: "User Manager",
        link: "/user-manager",
        logo: <UserIcon />,
        permissions: [1]
    },
    {
        id: "location-manager",
        title: "Location Manager",
        link: "/location-manager",
        logo: <AddLocationIcon />,
        permissions: [1]
    },
    {
        id: "category-manager",
        title: "Category Manager",
        link: "/category-manager",
        logo: <CategoryIcon />,
        permissions: [1]
    },
    {
        id: "ingredients-manager",
        title: "Ingredients Manager",
        link: "/ingredients-manager",
        logo: <SoupKitchenIcon />,
        permissions: [1]
    },
    {
        id: "nutritions-manager",
        title: "Nutritions Manager",
        link: "/nutritions-manager",
        logo: <HealthAndSafetyIcon />,
        permissions: [1]
    },
    {
        id: "products-manager",
        title: "Products Manager",
        link: "/products-manager",
        logo: <Inventory2Icon />,
        permissions: [1]
    },
    {
        id: "orders-manager",
        title: "Orders Manager",
        link: "/orders-manager",
        logo: <DvrIcon />,
        permissions: [1, 2]
    }
];
