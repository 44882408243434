import { get } from "lodash-es";
import { handleParseJson } from "../../helpers/utils";

export const ordersListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response.result
        // .map((dd) => {
        //     return {
        //         id: dd.id,
        //         name: dd.name,
        //         image: dd.image,
        //         category: dd["category.name"],
        //         status: dd.status,
        //         price: dd.price
        //     }
        // });
    } catch (error) {
        throw new Error(error);
    }
}

export const ordersGetParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

        // return {
        //     id: get(response, "id", ""),
        //     allergies: get(response, "allergies", ""),
        //     best_before: get(response, "best_before", ""),
        //     category_id: get(response, "category_id", ""),
        //     cuisine: get(response, "cuisine", ""),
        //     description: get(response, "description", ""),
        //     image: get(response, "image", ""),
        //     name: get(response, "name", ""),
        //     price: get(response, "price", ""),
        //     serving: get(response, "serving", ""),
        //     spice_evel: get(response, "spice_evel", ""),
        //     status: get(response, "status", ""),
        //     veg: get(response, "veg", ""),
        //     ingredients: response.ingredients.map((obj) => {
        //         return obj.id;
        //     }),
        //     nutrition_values: handleParseJson(response.nutrition_values)
        // }

    } catch (error) {
        throw new Error(error);
    }
}

export const OrdersParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

    } catch (error) {
        throw new Error(error);
    }
}