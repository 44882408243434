import React from "react";
import { FieldArray, Formik, getIn } from "formik";
import { ProductsValidation } from "./ProductsValidation";
import { Drawer, Grid, Paper, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import BasicSelect from "../../Common/Select";
import { useAddNewProductsMutation, useUpdateProductsMutation } from "../../../store/products/productsApis";
import { useGetCategoryListQuery } from "../../../store/category/categoryApis";
import FileUpload from "../../Common/FileUpload";
import StyledTextarea from "../../Common/StyledTextarea";
import { useGetIngredientsListQuery } from "../../../store/ingredients/ingredientsApis";
import { useGetNutritionsListQuery } from "../../../store/nutritions/nutritionsApis";
import MultipleSelectJson from "../../Common/MultipleSelectJson";
import AddIcon from "@mui/icons-material/Add"

export const ProductsAddDrawer = ({ initialValues, show, close, formType }) => {
    const dispatch = useDispatch();
    const { isLoading: categoryLoading, data: categoryData } = useGetCategoryListQuery({ limit: 100 }, { skip: !show });
    const { isLoading: ingredientsLoading, data: ingredientsData } = useGetIngredientsListQuery({ limit: 100 }, { skip: !show });
    const { isLoading: nutritionsLoading, data: nutritionsData } = useGetNutritionsListQuery({ limit: 100 }, { skip: !show });
    const [addNewProducts, { isLoading: addProductsLoading }] = useAddNewProductsMutation();
    const [updateProducts, { isLoading: updateProductsLoading }] = useUpdateProductsMutation();
    const disabled = formType === 'View' ? true : false;

    const onFormSubmit = async (values) => {
        try {
            let payload = { ...values }
            payload.ingredients = payload.ingredients.join(",");
            payload.nutrition_values = JSON.stringify(payload.nutrition_values);

            var form_data = new FormData();

            for (var key in payload) {
                form_data.append(key, payload[key]);
            }

            if (formType === "Edit") {
                await updateProducts(form_data).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Products updated successfully",
                    severity: "success",
                }))
            } else {
                await addNewProducts(form_data).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Products created successfully",
                    severity: "success",
                }))
            }
            close();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.payload?.message || "Something went wrong please try again later.",
                severity: "error",
            }))
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={ProductsValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '70%', sm: "70%", lg: "70%" } },
                    }}
                    onClose={() => close()}
                >
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={1} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType} Products</Typography>
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='name'
                                        name="name"
                                        label="Name *"
                                        onChange={handleChange("name")}
                                        value={values?.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='cuisine'
                                        name="cuisine"
                                        label="Cuisine *"
                                        onChange={handleChange("cuisine")}
                                        value={values?.cuisine || ""}
                                        error={Boolean(errors.cuisine)}
                                        helperText={errors.cuisine}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.category_id?.toString() || ""}
                                        onChange={handleChange("category_id")}
                                        displayEmpty
                                        label="Category *"
                                        name="category_id"
                                        id="category_id"
                                        items={categoryLoading ? [{ label: "Loading...", value: null }] : categoryData && categoryData.map((obj) => {
                                            return { label: obj.name, value: obj.id }
                                        })}
                                        error={Boolean(errors.status)}
                                        helperText={errors.status}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.status?.toString() || ""}
                                        onChange={handleChange("status")}
                                        displayEmpty
                                        label="Status *"
                                        name="status"
                                        id="status"
                                        items={[
                                            { label: "Active", value: "1" },
                                            { label: "In-active", value: "0" },
                                        ]}
                                        error={Boolean(errors.status)}
                                        helperText={errors.status}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <StyledTextarea
                                        fullWidth
                                        minRows={4}
                                        name="description"
                                        id="description"
                                        label="Description"
                                        disabled={disabled}
                                        value={values?.description?.toString() || ""}
                                        onChange={handleChange("description")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='price'
                                        name="price"
                                        label="Product Price *"
                                        onChange={handleChange("price")}
                                        value={values?.price || ""}
                                        error={Boolean(errors.price)}
                                        helperText={errors.price}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='spice_evel'
                                        name="spice_evel"
                                        label="Spice Level"
                                        onChange={handleChange("spice_evel")}
                                        value={values?.spice_evel || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='best_before'
                                        name="best_before"
                                        label="Best Before"
                                        onChange={handleChange("best_before")}
                                        value={values?.best_before || ""}
                                        error={Boolean(errors.best_before)}
                                        helperText={errors.best_before}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='allergies'
                                        name="allergies"
                                        label="Allergies"
                                        onChange={handleChange("allergies")}
                                        value={values?.allergies || ""}
                                        error={Boolean(errors.allergies)}
                                        helperText={errors.allergies}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='serving'
                                        name="serving"
                                        label="Serving"
                                        onChange={handleChange("serving")}
                                        value={values?.serving || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.veg?.toString() || ""}
                                        onChange={handleChange("veg")}
                                        displayEmpty
                                        label="Is Veg?"
                                        name="veg"
                                        id="veg"
                                        items={[
                                            { label: "Yes", value: "1" },
                                            { label: "No", value: "0" },
                                        ]}
                                        error={Boolean(errors.veg)}
                                        helperText={errors.veg}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <MultipleSelectJson
                                        size="small"
                                        defaultValue={values?.ingredients || []}
                                        onChange={(val) => setFieldValue("ingredients", val)}
                                        displayEmpty
                                        label="Ingredients *"
                                        name="ingredients"
                                        id="ingredients"
                                        options={ingredientsLoading ? [{ label: "Loading...", value: null }] : ingredientsData && ingredientsData.map((obj) => {
                                            return { label: obj.name, value: obj.id }
                                        })}
                                        error={Boolean(errors.ingredients)}
                                        helperText={errors.ingredients}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Paper sx={{ p: 3, borderRadius: "10px", width: "100%" }} elevation={2}>
                                        <FieldArray
                                            name="nutrition_values"
                                            render={({ push, remove }) => (
                                                <>
                                                    {
                                                        values?.nutrition_values && values.nutrition_values.length > 0 && values.nutrition_values.map(function (row, index) {

                                                            const label = `nutrition_values[${index}].label`;
                                                            const errorLabel = getIn(errors, label);

                                                            const nutValuesLabel = `nutrition_values[${index}].value`;
                                                            const errorNutValues = getIn(errors, nutValuesLabel);

                                                            return (
                                                                <Grid key={index} container spacing={2} pt={2} display={"flex"} justifyContent={"space-between"}>
                                                                    <Grid item xs={12} md={5}>
                                                                        <BasicSelect
                                                                            size="small"
                                                                            value={row?.label || ""}
                                                                            onChange={handleChange(label)}
                                                                            displayEmpty
                                                                            label="Select Nutritions"
                                                                            name={label}
                                                                            id={label}
                                                                            items={nutritionsLoading ? [{ label: "Loading...", value: null }] : nutritionsData && nutritionsData.map((obj) => {
                                                                                return { label: obj.name, value: obj.name }
                                                                            })}
                                                                            error={Boolean(errorLabel)}
                                                                            helperText={errorLabel}
                                                                            disabled={disabled}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={5}>
                                                                        <Input
                                                                            id={nutValuesLabel}
                                                                            name={nutValuesLabel}
                                                                            label="Enter Value"
                                                                            onChange={handleChange(nutValuesLabel)}
                                                                            value={row?.value || ""}
                                                                            fullWidth
                                                                            error={Boolean(errorNutValues)}
                                                                            helperText={errorNutValues}
                                                                            disabled={disabled}
                                                                        />
                                                                    </Grid>
                                                                    {
                                                                        !disabled
                                                                            ?
                                                                            <Grid item xs={1} md={1}>
                                                                                <IconButtonIcons color="danger" title="Remove" IconComponent={CloseIcon} onClick={() => remove(index)} />
                                                                            </Grid>
                                                                            :
                                                                            null
                                                                    }
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        !disabled
                                                            ?
                                                            <Grid container spacing={2} >
                                                                <Grid item xs={3} md={3}>
                                                                    <IconButtonIcons type="button" color="success" title="Add Slots" IconComponent={AddIcon} onClick={() => push({ label: "", value: "" })} />
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            null
                                                    }
                                                </>
                                            )}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileUpload limit={1} multiple={false} name='image' onChange={(e) => setFieldValue("image", e)} error={Boolean(errors.image)}
                                        helperText={errors.image} label="Browse product file to upload" value={values?.image || null} disabled={disabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="large" color="warning" type="button" onClick={() => close()}>Cancel</Button>
                                            <Button size="large" type="submit" loading={addProductsLoading || updateProductsLoading} onClick={() => handleSubmit()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }

                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}