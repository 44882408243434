import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { ProductsParser, productsListParser, productsGetParser } from './productsParser';

const productsApis = createApi({
    reducerPath: 'productsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Products"],
    endpoints: build => ({
        getProductsList: build.query({
            query: (params) => ({
                url: "/product/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productsListParser(response),
            providesTags: [{ type: 'Products', id: 'LIST' }],
            keepUnusedDataFor: 5,
        }),
        getSingleProduct: build.mutation({
            query: (params) => ({
                url: "/product",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productsGetParser(response),
            providesTags: (result, error, params) => [{ type: 'Products', id: params.id }],
            keepUnusedDataFor: 5,
        }),
        addNewProducts: build.mutation({
            query: (payload) => ({
                url: "/product",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => ProductsParser(response),
            invalidatesTags: [{ type: 'Products', id: 'LIST' }],
        }),
        updateProducts: build.mutation({
            query: (payload) => ({
                url: "/product",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => ProductsParser(response),
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: 'Products', id },
                        { type: 'Products', id: 'LIST' },
                    ]
                    : [{ type: 'Products', id: 'LIST' }],
        }),
        deleteProducts: build.mutation({
            query: (params) => ({
                url: "/product",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => ProductsParser(response),
            invalidatesTags: [{ type: 'Products', id: 'LIST' }],
        }),
    }),
});

export const { useGetProductsListQuery, useAddNewProductsMutation, useUpdateProductsMutation, useDeleteProductsMutation, useGetSingleProductMutation } = productsApis;
export default productsApis;