import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { OrdersParser, ordersListParser, ordersGetParser } from './ordersParser';

const ordersApis = createApi({
    reducerPath: 'ordersApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Orders"],
    endpoints: build => ({
        getOrdersList: build.query({
            query: (params) => ({
                url: "/order/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => ordersListParser(response),
            providesTags: [{ type: 'Orders', id: 'LIST' }],
            keepUnusedDataFor: 5,
        }),
        getSingleOrder: build.mutation({
            query: (params) => ({
                url: "/order",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => ordersGetParser(response),
            providesTags: (result, error, params) => [{ type: 'Orders', id: params.id }],
            keepUnusedDataFor: 5,
        }),
        updateOrders: build.mutation({
            query: (payload) => ({
                url: "/order/status",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => OrdersParser(response),
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: 'Orders', id },
                        { type: 'Orders', id: 'LIST' },
                    ]
                    : [{ type: 'Orders', id: 'LIST' }],
        })
    }),
});

export const { useGetOrdersListQuery, useUpdateOrdersMutation, useGetSingleOrderMutation } = ordersApis;
export default ordersApis;