import png from './png.png';
import jpg from './jpg.png';
import svg from './svg.png';
import defaultImage from './default.png';
import jpeg from './jpeg.png';

export const ImageConfig = {
  png,
  jpg,
  svg,
  'svg+xml': svg,
  default: defaultImage,
  jpeg,
};
