import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { LocationParser, locationListParser } from './locationParser';

const locationApis = createApi({
    reducerPath: 'locationApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("authtoken");
            if (token) {
                headers.set('Authorization', `BEAREAR ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Location"],
    endpoints: build => ({
        getLocationList: build.query({
            query: (params) => ({
                url: "/location",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => locationListParser(response),
            providesTags: ['Location'],
            keepUnusedDataFor: 5,
        }),
        addNewLocation: build.mutation({
            query: (payload) => ({
                url: "/location",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => LocationParser(response),
            invalidatesTags: ['Location'],
        }),
        updateLocation: build.mutation({
            query: (payload) => ({
                url: "/location",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => LocationParser(response),
            invalidatesTags: ['Location'],
        }),
        deleteLocation: build.mutation({
            query: (payload) => ({
                url: "/location",
                method: "DELETE",
                body: payload
            }),
            transformResponse: (response) => LocationParser(response),
            invalidatesTags: ['Location'],
        }),
    }),
});

export const { useGetLocationListQuery, useAddNewLocationMutation, useUpdateLocationMutation, useDeleteLocationMutation } = locationApis;
export default locationApis;