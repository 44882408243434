import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Chip, FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelectJson({ options, onChange, id, defaultValue, name, label, size = "small", ...other }) {

    const handleChange = (event) => {
        const { target: { value } } = event;
        onChange(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <FormControl fullWidth error={other.error}>
            <InputLabel size={size} id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                id={id}
                multiple
                name={name}
                value={defaultValue}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.map(obj => options.find(f => f.value === obj)?.label).join(", ")}
                MenuProps={MenuProps}
                size={size}
            // {...other}
            >
                {options.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                        <Checkbox
                            checked={defaultValue.find((val) => val === row.value) !== undefined}
                        />
                        <ListItemText primary={row.label} />
                    </MenuItem>
                ))}
            </Select>
            {other.error ? (
                <FormHelperText>{other.helperText}</FormHelperText>
            ) : null}
        </FormControl>
    );
}