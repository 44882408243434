import { Routes, Route, useNavigate } from "react-router-dom";
import DashboardView from "../pages/admin/Dashboard";
import UserManager from "../pages/admin/UserManager";
import LocationManager from "../pages/admin/LocationManager";
import CategoryManager from "../pages/admin/CategoryManager";
import IngredientsManager from "../pages/admin/IngredientsManager";
import NutritionsManager from "../pages/admin/NutritionsManager";
import ProductsManager from "../pages/admin/ProductsManager";
import OrdersManager from "../pages/admin/OrdersManager";
import { useEffect } from "react";
import { isAuth } from "../helpers/cookies";
import { Permissions } from "../helpers/constants";

const AdminRoutes = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth().permissions === Permissions.ORDERS) {
            navigate("/orders-manager");
        }
    }, [])

    return (
        <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/user-manager" element={<UserManager />} />
            <Route path="/location-manager" element={<LocationManager />} />
            <Route path="/category-manager" element={<CategoryManager />} />
            <Route path="/ingredients-manager" element={<IngredientsManager />} />
            <Route path="/nutritions-manager" element={<NutritionsManager />} />
            <Route path="/products-manager" element={<ProductsManager />} />
            <Route path="/orders-manager" element={<OrdersManager />} />
        </Routes>
    );
};

export default AdminRoutes;