import { get } from "lodash-es";

export const categoryListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response.result.map((dd) => {
            return {
                id: dd.id,
                name: dd.name,
                status: dd.status
            }
        });
    } catch (error) {
        throw new Error(error);
    }
}

export const CategoryParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

    } catch (error) {
        throw new Error(error);
    }
}